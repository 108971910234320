import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService{

  private duration = 5 * 1000;

  constructor(private snackBar: MatSnackBar) {
  }

  message(message: string) {
    if (!message) {
     return;
    }
    this.snackBar.open(message, '', {
      duration: this.duration,
    });
  }
}
