export enum MESSAGE_TYPES {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
}
export enum ERROR_MESSAGES {
  INVALID_URL = 'Invalid URL',
  INVALID_FOLDER_NAME = 'Invalid folder name',
  URL_EXISTED_ALREADY = 'URL Existed Already',
  DELETE_FOLDER_FAILED = 'Delete failed, please try again later',
  SAVE_FOLDER_FAILED = 'Oops, something is wrong, please try again later.',
}

export enum SUCCESS_MESSAGES {
  DELETE_FOLDER_SUCCESS = 'Delete Successfully',
  COPY_SUCCESS = 'Copy Successfully',
}
