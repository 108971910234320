import { Pipe, PipeTransform } from '@angular/core';
import {Folder} from '../../types/models/folder.model';

@Pipe({
  name: 'toFolderViewModel'
})
export class ToFolderViewModelPipe implements PipeTransform {

  transform(folder: Folder) {
    return {
      id: folder.id,
      name: folder.name,
      links: folder.links.map(({ url, name }) => ({url, name})),
      updatedAt: folder.updatedAt
    };
  }

}
