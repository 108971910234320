import {Component, Input, OnInit, AfterViewInit} from '@angular/core';
import {Folder, FolderEntityState} from '../../types/models/folder.model';
import {ShareDialogComponent} from '../share-dialog/share-dialog.component';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import * as moment from 'moment';
import {MatDialog} from '@angular/material/dialog';
import {FolderService} from '../../services/folder/folder.service';
import {FoldersService} from '../../services/folders/folders.service';
import {FoldersStore} from '../../services/folders/folders.store';
import {NotificationService} from '../../services/notification/notification.service';
import {ConfigurationService} from '../../services/configuration/configuration.service';
import {ERROR_MESSAGES, SUCCESS_MESSAGES} from '../../types/enums/message-types.enum';
import {Router} from '@angular/router';


@Component({
  selector: 'app-folder-item',
  templateUrl: './folder-item.component.html',
  styleUrls: ['./folder-item.component.less']
})
export class FolderItemComponent implements OnInit, AfterViewInit {
  @Input() folder: Folder;
  public isLoading = false;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private foldersService: FoldersService,
    private notificationService: NotificationService,
    private foldersEntityStore: FoldersStore,
    private folderService: FolderService,
    private configurationService: ConfigurationService) {
  }

  ngAfterViewInit() {
  }

  private openShareDialog() {
    const folderUrl = `${this.configurationService.getBaseUrl()}/folder/${this.folder.id}`;
    this.dialog.open(ShareDialogComponent, {
      width: '300px',
      data: folderUrl
    });
  }

  convertToLocalTime(time: string): string {
    return moment(time).format('l');
  }

  shortenCountNumber(originNumber: number): string {
    if (originNumber < 1000) {
      return originNumber.toString();
    }
    return `${Math.floor(originNumber / 1000)}k`;
  }

  onShareHandler(event: Event) {
    event.stopPropagation();
    this.openShareDialog();
  }

  onTriggerMenuHandler(event: Event) {
    event.stopPropagation();
  }

  async goToFolderDetail(folderId: number) {
    await this.router.navigate([`folder/${folderId}`]);
  }

  private deleteFolder(id: number) {
    this.isLoading = true;
    this.folderService.deleteFolder(id).subscribe({
      next: () => {
        this.notificationService.message(SUCCESS_MESSAGES.DELETE_FOLDER_SUCCESS);
        this.foldersEntityStore.remove(id);
      },
      error: error => {
        this.isLoading = false;
        this.notificationService.message(ERROR_MESSAGES.DELETE_FOLDER_FAILED);
      }
    });
  }

  onDeleteFolderHandler(id: number) {
    this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        message: `Delete ${this.folder.name}?`,
        onConfirm: () => {
          this.deleteFolder(id);
        }
      }
    });
  }

  ngOnInit(): void {
  }

}

