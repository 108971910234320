<div class="error"  fxLayout="column" fxLayoutAlign="center center">
  <div fxLayout="row" fxLayoutAlign="center center">
    <p class="error-message"><ng-content></ng-content></p>
  </div>
  <div class="btn-group" fxLayout="row" fxLayoutAlign="center center">
    <a href="/login" *ngIf="showLoginBtn" class="btn">
      <button mat-flat-button color="primary">
        Login
      </button>
    </a>
    <a href="/dashboard" *ngIf="showDashboardBtn" class="btn">
      <button mat-flat-button color="primary">
        Dashboard
      </button>
    </a>
  </div>
</div>

