import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiClientService} from '../api-client/api-client.service';
import {catchError, map, tap} from 'rxjs/operators';
import {Observable, of, throwError} from 'rxjs';
import {UserStore} from './user.store';
import {User} from '../../types/models/user.model';
import {Router} from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private userStore: UserStore,
    private httpClient: HttpClient,
    private router: Router,
    private apiClient: ApiClientService) {
  }

  private handleError = (error: any): Observable<any> | Promise<boolean>  => {
    this.userStore.setLoading(false);
    this.userStore.setError(error);
    this.userStore.reset();
    return throwError(error);
  }

  getUserProfile = () => {
    this.userStore.setLoading(true);
    return this.httpClient.get(this.apiClient.profile(), {
      withCredentials: true,
    }).pipe(
      map((response: User) => {
        this.userStore.updateUserProfile(response);
        this.userStore.setLoading(false);
      }),
      catchError(this.handleError)
    );
  };
}
