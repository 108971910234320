import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dummy',
  templateUrl: './dummy.component.html',
  styleUrls: ['./dummy.component.less']
})
export class DummyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
