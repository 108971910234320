import { Component } from '@angular/core';
import {ConfigurationService} from './services/configuration/configuration.service';
import {ActivatedRoute, Router} from '@angular/router';
import {map, tap} from 'rxjs/operators';
import {LocationChangeEvent, Location} from '@angular/common';
import {PerfService} from './services/performance/performance.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  constructor(
    private configurationService: ConfigurationService,
    private router: Router,
    private location: Location,
    private perfService: PerfService
  ) {
    window.addEventListener('load', (event) => {
      perfService.recordBrowserPainting();
    });
  }
  get hasError() {
    return this.location.path().toLowerCase().indexOf('login') > -1
      || this.location.path().toLowerCase().indexOf('forbidden') > -1;
  }
  title = this.configurationService.getTitle();
}
