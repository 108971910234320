import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {tap, catchError, map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {FoldersStore} from './folders.store';
import {FoldersResponse} from '../../types/models/folder.model';
import {ApiClientService} from '../api-client/api-client.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class FoldersService {
  constructor(
    private foldersEntityStore: FoldersStore,
    private httpClient: HttpClient,
    private router: Router,
    private apiClient: ApiClientService) {
  }
  private handleError = (error: any): Promise<boolean> | Observable<any> => {
    this.foldersEntityStore.setLoading(false);
    this.foldersEntityStore.setError(error);
    return of(error);
  }
  private reset = (): void => {
    this.foldersEntityStore.reset();
    this.foldersEntityStore.setLoading(true);
    this.foldersEntityStore.setError(null);
  }
  getFolders = (page: number = 1) => {
    this.reset();
    return this.httpClient.get(this.apiClient.folders(page), {
      withCredentials: true,
    }).pipe(
      tap((response: FoldersResponse) => {
        this.foldersEntityStore.set(response.data);
        this.foldersEntityStore.updatePagination(response.pagination);
        this.foldersEntityStore.setLoading(false);
      }),
      catchError(this.handleError)
    );
  }
}
