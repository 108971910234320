<a mat-ripple [href]="url" target="_blank" class="link" fxLayout="row" fxLayoutAlign="space-between center">
  <ng-container *ngIf="!!name; else urlTemplate">
    <div>
      <label class="title">{{name}}</label>
      <p class="description">{{url}}</p>
    </div>
  </ng-container>
  <ng-template #urlTemplate>
    <div>
      <label class="title" [title]="url">{{url}}</label>
    </div>
  </ng-template>
  <mat-icon *ngIf="showAction" (click)="onChangeTitleHandler($event)" class="action btn-add-name-to-link">comment</mat-icon>
  <mat-icon *ngIf="showAction" (click)="onDeleteLinkHandler($event)" class="action btn-delete-link">close</mat-icon>
</a>
