<app-folder-layout>
  <ng-container header>
    <h1 class="title" [title]="form.value.name">
      <mat-icon class="icon" (click)="onOpenNameFormDialog()" *ngIf="editable">edit</mat-icon>
      <span class="content">{{form.value.name}}</span>
    </h1>
  </ng-container>
  <ng-container body>
    <div class="panel-content single-folder-container">
      <ng-container>
        <div class="meta-info">
          <div *ngIf="!!updatedAt" class="modify-time">
            <mat-icon class="icon">schedule</mat-icon>
            <span class="content">{{updatedAt | toLocalTime}}</span>
          </div>
          <div class="action-group">
            <div *ngIf="!editable" (click)="onShareHandler($event)" class="btn-share action">
              <mat-icon class="icon">share</mat-icon>
              <span class="content">Share</span>
            </div>
            <div *ngIf="showEditButton && !editable" (click)="router.navigate(['/folder/' + this.folder.id +  '/edit']);" class="btn-edit action">
              <mat-icon class="icon">edit</mat-icon>
              <span class="content">Edit</span>
            </div>
          </div>
        </div>
        <app-url-form [existingLinks]="this.links.value" *ngIf="editable"
          (onUrlSubmit)="this.onUrlSubmitHandler($event)"></app-url-form>
        <app-link-item *ngFor="let link of links.controls; let i = index" [url]="link.value.url" [name]="link.value.name"
          [showAction]="editable" (onDelete)="onDeleteLinkHandler(i)" (onChangeTitle)="onChangeTitleHandler(i, $event)"></app-link-item>
      </ng-container>
    </div>
    <app-action-bar *ngIf="editable">
      <button mat-flat-button color="basic" type="button" class="cancel-btn"
        (click)="this.onCancelHandler()">Cancel</button>
      <button mat-flat-button color="primary" type="button" [disabled]="disableSubmit"
        (click)="this.onSubmitHandler()">Save
      </button>
    </app-action-bar>
  </ng-container>
</app-folder-layout>