<ng-container *ngIf="isLoading$ | async">
  <app-page-loading-indicator></app-page-loading-indicator>
</ng-container>

<ng-container *ngIf="(error$ | async) else folderDetail">
  <app-error-message></app-error-message>
</ng-container>

<ng-template #folderDetail>
  <ng-container *ngIf="(folder$ | async )">
    <app-single-folder-form [showEditButton]="(folder$ | async).editable" [editable]="false" [folder]="folder$ | async | toFolderViewModel">
    </app-single-folder-form>
  </ng-container>
</ng-template>

