<ng-container *ngIf="hasError else content">
  <router-outlet></router-outlet>
</ng-container>
<ng-template #content>
  <mat-drawer-container class="container">
    <mat-drawer mode="over">Drawer content</mat-drawer>
    <mat-drawer-content>
      <app-header></app-header>
      <router-outlet></router-outlet>
    </mat-drawer-content>
  </mat-drawer-container>
</ng-template>
