import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LOADING_ACTION_TYPES} from '../../types/enums/loading-action-types.enum';

@Component({
  selector: 'app-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.less']
})
export class LoadingModalComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<LoadingModalComponent>, @Inject(MAT_DIALOG_DATA) public data: LOADING_ACTION_TYPES) {}
  ngOnInit() {

  }
}
