import {Injectable} from '@angular/core';
import {ConfigurationService} from '../configuration/configuration.service';
import {ApiClientService} from '../api-client/api-client.service';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private auth2 = null;
  onVerifyGoogleAuthIdToken$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(
    private configurationService: ConfigurationService,
    private httpClient: HttpClient,
    private apiClient: ApiClientService,
    private router: Router,
    private route: ActivatedRoute,
  ) {

    const initialize = () => {
      // @ts-ignore
      const gapi = window.gapi;
      if (!gapi) {
        return;
      }
      gapi.load('auth2', () => {
        this.auth2 = gapi.auth2.init({
          client_id: this.configurationService.getGoogleClientID(),
          scope: 'profile email',
        });
        if (!document.querySelector('#login-via-google-button')) {
          const targetNode = document.querySelector('body');
          const config = {childList: true, subtree: true};
          const callback = () => {
            const button = document.querySelector('#login-via-google-button');
            if (!!button) {
              this.auth2.attachClickHandler(button, {}, this.onSignInSucceed, this.onSignInFailed);
              observer.disconnect();
            }
          };
          const observer = new MutationObserver(callback);
          observer.observe(targetNode, config);
        } else {
          this.auth2.attachClickHandler(
            document.querySelector('#login-via-google-button'),
            {},
            this.onSignInSucceed,
            this.onSignInFailed);
        }
      });
    };

    // @ts-ignore
    window.initializeGoogleSDK = initialize;
    // @ts-ignore
    if (!window.ready2InitGoogleSDK) {
      return;
    }
    initialize();
  }
  public logout() {
    if (this.auth2) {
      this.auth2.signOut();
    }
    return this.httpClient.post(this.apiClient.logout(), {}, {
      responseType: 'text',
      withCredentials: true
    });
  }

  private verify(idToken) {
    return this.httpClient.get(this.apiClient.verify(idToken), {
      responseType: 'text',
      withCredentials: true
    });
  }
  private onSignInFailed(error) {
    window.location.pathname = '/forbidden';
  }

  private onSignInSucceed = (googleUser) => {
    const idToken = googleUser.getAuthResponse().id_token;

    this.onVerifyGoogleAuthIdToken$.next(true);
    this.verify(idToken).subscribe({
      next: async () => {
        window.location.pathname = '/dashboard';
      },
      error: error => {
        window.location.pathname = '/forbidden';
      },
      complete: () => {
        this.onVerifyGoogleAuthIdToken$.next(false);
      }
    });
  }
}
