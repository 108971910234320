import {AfterViewInit, Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ApiClientService} from '../../services/api-client/api-client.service';
import {NotificationService} from '../../services/notification/notification.service';
import {FolderService} from '../../services/folder/folder.service';
import {EditedFolderWithRawLinks, Folder} from '../../types/models/folder.model';
import {ERROR_MESSAGES} from '../../types/enums/message-types.enum';
import {LoadingModalComponent} from '../loading-modal/loading-modal.component';
import {LOADING_ACTION_TYPES} from '../../types/enums/loading-action-types.enum';
import {Observable} from 'rxjs';
import {FolderQuery} from '../../services/folder/folder.query';
import {ActivatedRoute, Router} from '@angular/router';
import {UserQuery} from '../../services/user/user.query';
import {PerfService} from '../../services/performance/performance.service';

@Component({
  selector: 'app-single-folder-edit-container',
  templateUrl: './single-folder-edit-container.component.html',
  styleUrls: ['./single-folder-edit-container.component.less']
})
export class SingleFolderEditContainerComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private apiClientService: ApiClientService,
    private notificationService: NotificationService,
    private folderQuery: FolderQuery,
    private folderService: FolderService,
    private route: ActivatedRoute,
    private userQuery: UserQuery,
    private router: Router,
    private perfService: PerfService
) {
    this.userQuery.loadingCompletedAndConfirmIsNotLogin()
      .subscribe(result => {
        if (result) {
          window.location.pathname = '/login';
        }
      });
  }

  folderId = null;

  folder$: Observable<Folder>;
  isLoading$: Observable<boolean>;
  error$: Observable<any>;

  editFolder(updatedFolder: EditedFolderWithRawLinks) {
    this.openSavingModal();
    this.folderService.editFolder(this.folderId, updatedFolder).subscribe({
      next: async () => {
        this.closeSavingModal();
        await this.router.navigate( [ `/folder/${this.folderId}`] );
      },
      error: async () => {
        this.notificationService.message(ERROR_MESSAGES.SAVE_FOLDER_FAILED);
        this.closeSavingModal();
      }
    });
  }

  loadData() {
    this.folderService.getFolder(this.folderId).subscribe();
    this.folder$ = this.folderQuery.getFolder();
    this.isLoading$ = this.folderQuery.selectLoading();
    this.error$ = this.folderQuery.selectError();
  }
  ngOnInit(): void {
    this.perfService.trackElementShowUp(
      'folder-edit:visible',
      '.single-folder-container'
    );
    this.folderId = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    this.loadData();
  }

  openSavingModal(): void {
    this.dialog.open(LoadingModalComponent, {
      width: '300px',
      height: '120px',
      disableClose: true,
      data: LOADING_ACTION_TYPES.SAVING
    });
  }

  closeSavingModal(): void {
    this.dialog.closeAll();
  }
}
