import {AbstractControl} from '@angular/forms';
import validator from 'validator';

export const INCORRECT_FORMAT = 'INCORRECT_FORMAT';
export const REQUIRED = 'required';
export const DUPLICATED = 'DUPLICATED';
export const MAX_LENGTH = 'MAX_LENGTH';
const folderNameMaxLength = 140;
export const URL_ERROR2MESSAGE = {
  [INCORRECT_FORMAT]: 'Invalid URL',
  [REQUIRED]: 'Required',
  [DUPLICATED]: 'Duplicated URL'
};
export const FOLDER_NAME_ERROR2MESSAGE = {
  [INCORRECT_FORMAT]: 'Invalid folder name',
  [REQUIRED]: 'Required',
  [MAX_LENGTH]: `Max ${folderNameMaxLength} characters`,
};

export function urlFormatValidator(control: AbstractControl): { [key: string]: any } | null {
  const urlIsLegal = validator.isURL(control.value, {
    require_protocol: true,
    require_valid_protocol: true
  });
  if (!urlIsLegal) {
    return {[INCORRECT_FORMAT]: true};
  }
  return null;
}

export function folderNameValidator(control: AbstractControl): { [key: string]: any } | null {
  const folderNameRegex = /.*/;
  const folderNameFormatIsLegal = folderNameRegex.test(control.value);
  const folderNameLengthIsLegal = validator.isLength(control.value.trim(), {
    min: 1,
    max: folderNameMaxLength
  });
  if (!folderNameFormatIsLegal) {
    return {[INCORRECT_FORMAT]: true};
  }
  if (!folderNameLengthIsLegal) {
    return {[MAX_LENGTH]: true};
  }
  return null;
}
