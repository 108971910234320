import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-error-layout',
  templateUrl: './error-layout.component.html',
  styleUrls: ['./error-layout.component.less']
})
export class ErrorLayoutComponent implements OnInit {

  @Input() showLoginBtn = false;
  @Input() showDashboardBtn = false;

  constructor() { }

  ngOnInit(): void {
  }

}
