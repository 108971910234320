import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ERROR_MESSAGES} from '../../types/enums/message-types.enum';
import {LoadingModalComponent} from '../loading-modal/loading-modal.component';
import {LOADING_ACTION_TYPES} from '../../types/enums/loading-action-types.enum';
import {MatDialog} from '@angular/material/dialog';
import {ApiClientService} from '../../services/api-client/api-client.service';
import {NotificationService} from '../../services/notification/notification.service';
import {FolderService} from '../../services/folder/folder.service';
import {CreatedFolder, CreatedFolderWithRawLinks, FolderViewModel} from '../../types/models/folder.model';
import {Router} from '@angular/router';
import {UserQuery} from '../../services/user/user.query';
import {PerfService} from '../../services/performance/performance.service';

@Component({
  selector: 'app-single-folder-create-container',
  templateUrl: './single-folder-create-container.component.html',
  styleUrls: ['./single-folder-create-container.component.less']
})
export class SingleFolderCreateContainerComponent implements OnInit, AfterViewInit {

  constructor(
    public dialog: MatDialog,
    private apiClientService: ApiClientService,
    private notificationService: NotificationService,
    private folderService: FolderService,
    private userQuery: UserQuery,
    private router: Router,
    private perfService: PerfService
) {
    this.userQuery.loadingCompletedAndConfirmIsNotLogin()
      .subscribe(result => {
        if (result) {
          window.location.pathname = '/login';
        }
      });
  }

  defaultFolderViewModel: FolderViewModel = {
    name: 'New Folder',
    links: [],
    updatedAt: null
  };

  ngOnInit(): void {
  }

  ngAfterViewInit() {
  }

  createFolder(newFolder: CreatedFolderWithRawLinks) {
    this.openSavingModal();
    this.folderService.createFolder(newFolder).subscribe(async (data) => {
      this.closeSavingModal();
      await this.router.navigate( [ `/folder/${data.id}`] );
    }, () => {
      this.closeSavingModal();
      this.notificationService.message(ERROR_MESSAGES.SAVE_FOLDER_FAILED);
    });
  }

  openSavingModal(): void {
    this.dialog.open(LoadingModalComponent, {
      id: 'create-loading-dialog',
      width: '300px',
      height: '120px',
      disableClose: true,
      data: LOADING_ACTION_TYPES.SAVING
    });
  }

  closeSavingModal(): void {
    this.dialog.closeAll();
  }
}
