import {Injectable} from '@angular/core';
import {Query} from '@datorama/akita';
import {Observable} from 'rxjs';
import {FolderStore, FolderState} from './folder.store';
import {Folder} from '../../types/models/folder.model';

@Injectable({
  providedIn: 'root',
})
export class FolderQuery extends Query<FolderState> {
  constructor(protected store: FolderStore) {
    super(store);
  }
  getFolder(): Observable<Folder> {
    return this.select(state => state.folder);
  }
}
