import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-loading-indicator',
  templateUrl: './page-loading-indicator.component.html',
  styleUrls: ['./page-loading-indicator.component.less']
})
export class PageLoadingIndicatorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
