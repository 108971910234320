import { Injectable } from '@angular/core';
import {NavigationStart, Router, Event} from '@angular/router';

function stringifyPropertyValue(target: object) {
  const result = {};
  for (const key in target) {
    if (target.hasOwnProperty(key) && !!target[key] && target[key].toString) {
      result[key] = target[key].toString();
    }
  }
  return result;
}

@Injectable({
  providedIn: 'root'
})
export class PerfService {

  private navigationStartTime: number;
  private nextPathWillNavigateByAppRoute = false;

  private browserPaintingMetricsRecorded = false;
  private resourceMetricsRecorded = false;

  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.navigationStartTime = performance.now();
      }
    });
  }

  public trackElementShowUp(metricName: string, targetQueryString: string) {
    // @ts-ignore
    if (!window.appInsights) {
      return;
    }
    const observer = new MutationObserver(mutations => {
      for (const item of mutations) {
        if (document.querySelector(targetQueryString)) {
          this.recordBrowserPainting();
          this.recordResourceTiming();
          const value = this.nextPathWillNavigateByAppRoute ? performance.now() - this.navigationStartTime : performance.now();
          if (!this.nextPathWillNavigateByAppRoute) {
            this.nextPathWillNavigateByAppRoute = true;
          }
          // @ts-ignore
          window.appInsights.trackMetric({
            name: metricName,
            average: value
          }, {
            url: location.href,
          });
          observer.disconnect();
          return;
        }
      }
    });
    observer.observe(document.querySelector('body'), {
      subtree: true,
      childList: true
    });
  }


  private recordEachResource(entry: any): void {
    const {
      initiatorType,
      entryType,
      connectEnd,
      connectStart,
      decodedBodySize,
      domainLookupEnd,
      domainLookupStart,
      duration,
      encodedBodySize,
      fetchStart,
      name,
      redirectEnd,
      redirectStart,
      requestStart,
      responseEnd,
      responseStart,
      startTime,
      transferSize,
    } = entry;

    // @ts-ignore
    window.appInsights.trackMetric({
      name: `${entryType}:${initiatorType}:${name}`,
      average: duration,
    }, stringifyPropertyValue({
      fetchStart,
      responseStart,
      transferSize,
      url: name,
    }));
  }

  public recordResourceTiming(): void {
    if (this.resourceMetricsRecorded) {
      return;
    }
    // @ts-ignore
    if (!window.performance || !window.appInsights) {
      return;
    }
    this.resourceMetricsRecorded = true;
    const entries = window.performance.getEntries();

    entries
      // @ts-ignore
      .filter(({initiatorType, entryType}) => initiatorType === 'script' && entryType === 'resource')
      .forEach(this.recordEachResource);

    entries
      // @ts-ignore
      .filter(({initiatorType, entryType}) => initiatorType === 'link' && entryType === 'resource')
      .forEach(this.recordEachResource);
  }

  public getRequestPerf(url: string): void {
    // @ts-ignore
    if (!window.performance || !window.appInsights) {
      return;
    }
    const entries = window.performance.getEntries();
    entries
      // @ts-ignore
      .filter(item => item.initiatorType === "xmlhttprequest" && item.name.indexOf(url) > -1)
      .forEach(this.recordEachResource);
  }

  public recordBrowserPainting(): void {
    if (this.browserPaintingMetricsRecorded) {
      return;
    }
    // @ts-ignore
    if (!window.performance || !window.appInsights) {
      return;
    }
    this.browserPaintingMetricsRecorded = true;
    const entries = window.performance.getEntries();
    const paintEntries = entries.filter(entry => entry.entryType === 'paint');
    paintEntries.forEach(paintEntry => {
      const {name, startTime: value} = paintEntry;
      // @ts-ignore
      window.appInsights.trackMetric({
        name: `browser:${name}`,
        average: value
      }, {
        url: location.href,
      });
    });
  }

}
