import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {PlaygroundComponent} from './components/playground/playground.component';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {SingleFolderCreateContainerComponent} from './components/single-folder-create-container/single-folder-create-container.component';
import {SingleFolderEditContainerComponent} from './components/single-folder-edit-container/single-folder-edit-container.component';
import {SingleFolderContainerComponent} from './components/single-folder-container/single-folder-container.component';
import {ErrorMessageComponent} from './components/error-message/error-message.component';
import {LoginComponent} from './components/login/login.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'folder/create', component: SingleFolderCreateContainerComponent },
  { path: 'folder/:id/edit', component: SingleFolderEditContainerComponent },
  { path: 'folder/:id', component: SingleFolderContainerComponent },
  { path: 'forbidden', component: ErrorMessageComponent },
  { path: 'error', component: ErrorMessageComponent },
  { path: 'playground', component: PlaygroundComponent },
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
