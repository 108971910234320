import {Component, Inject, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import QRCode from 'qrcode';
import {MatSnackBar} from '@angular/material/snack-bar';

export interface ConfirmDialogData {
  message: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.less']
})
export class ConfirmDialogComponent implements OnInit, AfterViewInit {

  ngOnInit(): void {
  }

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
              private snackBar: MatSnackBar) {
  }

  ngAfterViewInit() {
  }

  onConfirmHandler() {
    if (this.data.onConfirm) {
      this.data.onConfirm();
    }
    this.dialogRef.close();
  }

  onCancelHandler() {
    if (this.data.onCancel) {
      this.data.onCancel();
    }
    this.dialogRef.close();
  }
}
