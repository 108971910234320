import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Folder} from '../../types/models/folder.model';
import {FolderService} from '../../services/folder/folder.service';
import {FolderQuery} from '../../services/folder/folder.query';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute} from '@angular/router';
import {PerfService} from '../../services/performance/performance.service';

@Component({
  selector: 'app-single-folder-container',
  templateUrl: './single-folder-container.component.html',
  styleUrls: ['./single-folder-container.component.less']
})
export class SingleFolderContainerComponent implements OnInit {

  folderId = null;

  folder$: Observable<Folder>;
  isLoading$: Observable<boolean>;
  error$: Observable<any>;

  constructor(
    private folderService: FolderService,
    private folderQuery: FolderQuery,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private perfService: PerfService
) { }

  loadData() {
    this.folderId = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    // Do not delete handlers in the subscribe callback function, this will cause test failed
    this.folderService.getFolder(this.folderId).subscribe({ error: () => {} });
    this.folder$ = this.folderQuery.getFolder();
    this.isLoading$ = this.folderQuery.selectLoading();
    this.error$ = this.folderQuery.selectError();
  }
  ngOnInit(): void {
    this.loadData();
    this.perfService.trackElementShowUp(
      'folder-detail:visible',
      '.single-folder-container'
    );

  }
}
