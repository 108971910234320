import {Injectable} from '@angular/core';
import {Query} from '@datorama/akita';
import {Observable, of} from 'rxjs';
import {UserState, UserStore} from './user.store';
import {User} from '../../types/models/user.model';
import {combineLatestWith, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserQuery extends Query<UserState> {
  constructor(protected store: UserStore) {
    super(store);
  }
  getProfile(): Observable<User> {
    return this.select(state => state.profile);
  }
  checkIsLogin(): Observable<boolean> {
    return this.select(state => state.isLogin);
  }
  loadingCompletedAndConfirmIsNotLogin(): Observable<boolean>{
    const isLoading$ = this.selectLoading();
    const isLogin$ = this.checkIsLogin();

    return isLoading$.pipe(
      combineLatestWith(isLogin$),
      map(([isLoading, isLogin]) => {
        if (!isLoading && !isLogin) {
          return true;
        }
        return false;
      })
    );
  }
}
