import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {FoldersStore} from './folders.store';
import {Folder, FolderEntityState} from '../../types/models/folder.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FoldersQuery extends QueryEntity<FolderEntityState> {
  constructor(protected store: FoldersStore) {
    super(store);
  }
  getFolders(): Observable<Folder[]> {
    return this.selectAll();
  }
  getPagination(): Observable<any> {
    return this.select(state => state.ui.pagination);
  }
  getUI(): Observable<any> {
    return this.select(state => state.ui);
  }
}
