import {Injectable} from '@angular/core';
import {StoreConfig, Store} from '@datorama/akita';
import {User} from '../../types/models/user.model';

export interface UserState {
  profile?: User;
  isLogin: boolean;
}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({name: 'user', resettable: true})
export class UserStore extends Store<UserState> {
  constructor() {
    super({
      isLogin: false,
      profile: null
    });
  }
  updateUserProfile(user: User) {
    this.update({
      isLogin: true,
      profile: user
    });
  }
}
