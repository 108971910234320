<mat-progress-bar class="loading-meta-bar" *ngIf="(isLoading$ | async) || onLogout" mode="indeterminate"></mat-progress-bar>
<mat-toolbar class="nav-bar">
  <div class="header"  fxLayout="row" fxLayoutAlign="space-between center">
    <a class="logo" [routerLink]="'/'" ></a>
    <div class="profile" fxLayout="row" fxLayoutAlign="center center">
      <mat-icon *ngIf="!(isLoading$ | async) && (isLogin$ | async)" class="header-menu-icon" [matMenuTriggerFor]="profileMenu">person</mat-icon>
      <mat-icon class="header-menu-icon" [matMenuTriggerFor]="helpMenu">help</mat-icon>
      <mat-menu #profileMenu="matMenu">
        <button class="logout-btn" mat-menu-item (click)="logoutHandler()">
          <mat-icon>power_settings_new</mat-icon>
          <span>Log out</span>
        </button>
      </mat-menu>
      <mat-menu #helpMenu="matMenu">
        <a class="contact"  href="mailto:hi@site2share.com" mat-menu-item>
          <span>Contact Us</span>
        </a>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>

<!-- <app-banner [text]="'Login To Share Your Bookmark'" *ngIf="!(isLoading$ | async) && !(isLogin$ | async)" [nativeLink]="'/login'"> -->
<!-- </app-banner> -->
