<!--<app-banner [extraClassname]="'not-support-edit-on-mobile-banner'">-->
<!--  <span>Not Support Edit On Mobile Device</span>-->
<!--</app-banner>-->

<ng-container *ngIf="(isLoading$ | async) else loadingCompleteTemplate">
  <app-page-loading-indicator></app-page-loading-indicator>
</ng-container>

<ng-template #loadingCompleteTemplate>
  <div class="container">

    <ng-container *ngIf="(error$ | async) else foldersTemplate">
      <app-error-message></app-error-message>
    </ng-container>

    <ng-template #foldersTemplate>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h1 class="title">Dashboard</h1>
        <a class="create-folder-link" [routerLink]="'/folder/create'">
          <button mat-flat-button color="primary">
            Add Folder
          </button>
        </a>
      </div>
      <div class="my-folders-container">
        <ng-container *ngIf="(folders$ | async).length else noDataTemplate">
          <app-folder-item *ngFor="let folder of (folders$ | async)" [folder]="folder"></app-folder-item>
          <app-pagination
            *ngIf="(pagination$ | async)"
            (onTurnPrevPage)="loadData($event)"
            (onTurnNextPage)="loadData($event)"
            (onGoToPage)="loadData($event)"
            [total]="(pagination$ | async).total"
            [current]="(pagination$ | async).current"></app-pagination>
        </ng-container>
        <ng-template #noDataTemplate>
          <app-error-layout>No Folders</app-error-layout>
        </ng-template>
      </div>
    </ng-template>

  </div>
</ng-template>
