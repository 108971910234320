import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiClientService} from '../api-client/api-client.service';
import {catchError, map, tap} from 'rxjs/operators';
import {Observable, of, throwError} from 'rxjs';
import {FolderStore} from './folder.store';
import {FoldersStore} from '../folders/folders.store';
import {CreatedFolder, CreatedFolderWithRawLinks, EditedFolder, EditedFolderWithRawLinks, Folder} from '../../types/models/folder.model';
import {Router} from '@angular/router';
import {PerfService} from '../../services/performance/performance.service';


@Injectable({
  providedIn: 'root'
})
export class FolderService {

  constructor(
    private foldersEntityStore: FoldersStore,
    private folderStore: FolderStore,
    private httpClient: HttpClient,
    private router: Router,
    private perfService: PerfService,
    private apiClient: ApiClientService) {
  }

  private handleError = (error: any): Observable<any> | Promise<boolean>  => {
    this.folderStore.setLoading(false);
    this.folderStore.setError(error);
    return throwError(error);
  }

  private reset = (): void => {
    this.folderStore.reset();
    this.folderStore.setLoading(true);
    this.folderStore.setError(null);
  };

  getFolder = (folderId: number) => {
    this.reset();
    const url = this.apiClient.folder(folderId);
    return this.httpClient.get(url, {
      withCredentials: true,
    }).pipe(
      map((response: Folder) => {
        this.perfService.getRequestPerf(url);
        this.folderStore.updateFolder(response);
        this.folderStore.setLoading(false);
      }),
      catchError(this.handleError)
    );
  };

  deleteFolder = (folderId: number) => {
    return this.httpClient.delete(this.apiClient.folder(folderId), {
      responseType: 'text',
      withCredentials: true
    }).pipe(
      tap(() => {
        this.foldersEntityStore.remove(folderId);
      }),
      catchError(this.handleError)
    );
  };

  createFolder = (newFolder: CreatedFolderWithRawLinks) => {
    const newFolderWithBackendRequiredLinks: CreatedFolder = {
      name: newFolder.name,
      links: newFolder.links.map(({url, name}) => ({
        url,
        name
      }))
    };
    return this.httpClient.post(this.apiClient.folder(), newFolderWithBackendRequiredLinks, {
      withCredentials: true,
    }).pipe(
      catchError(this.handleError)
    );
  };

  editFolder = (folderId: number | string, updatedFolder: EditedFolderWithRawLinks) => {
    const updatedFolderWithBackendRequiredLinks: EditedFolder = {
      id: updatedFolder.id,
      name: updatedFolder.name,
      links: updatedFolder.links.map(({url, name}) => ({
        url,
        name
      }))
    };
    return this.httpClient.put(this.apiClient.folder(folderId), updatedFolderWithBackendRequiredLinks, {
      responseType: 'text',
      withCredentials: true
    }).pipe(
      catchError(this.handleError)
    );
  };
}
