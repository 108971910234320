import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {folderNameValidator, FOLDER_NAME_ERROR2MESSAGE, REQUIRED, MAX_LENGTH, INCORRECT_FORMAT} from '../helper';

export interface FolderNameDialogData {
  name: string;
  onConfirm: (name: string) => {};
}
@Component({
  selector: 'app-folder-name-form-dialog',
  templateUrl: './folder-name-form-dialog.component.html',
  styleUrls: ['./folder-name-form-dialog.component.less']
})
export class FolderNameFormDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<FolderNameFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FolderNameDialogData,
    private snackBar: MatSnackBar
  ) { }

  form = this.initFormGroup();

  ngOnInit(): void {
    this.form = this.initFormGroup(this.data.name);
  }

  private initFormGroup(folderName: string = '') {
    return new FormGroup({
      name: new FormControl(folderName, [
        Validators.required,
        folderNameValidator
      ]),
    });
  }

  private closeDialog() {
    this.dialogRef.close();
    this.form.patchValue({
      name: '',
    });
    this.form.markAsPristine();
  }

  onSubmitHandler() {
    if (this.form.controls.name.errors) {
      return;
    }
    this.data.onConfirm(this.form.controls.name.value.trim());
    this.closeDialog();
  }

  getErrorMessage() {
    if (!this.form.controls.name.dirty) {
      return null;
    }
    if (this.form.controls.name.hasError(REQUIRED)) {
      return FOLDER_NAME_ERROR2MESSAGE[REQUIRED];
    }
    if (this.form.controls.name.hasError(MAX_LENGTH)) {
      return FOLDER_NAME_ERROR2MESSAGE[MAX_LENGTH];
    }
    if (this.form.controls.name.hasError(INCORRECT_FORMAT)) {
      return FOLDER_NAME_ERROR2MESSAGE[INCORRECT_FORMAT];
    }
    return null;
  }

  onCancelHandler() {
    this.form.patchValue({
      name: '',
    });
    this.dialogRef.close();
    this.closeDialog();
  }
}
