<div mat-dialog-content class="folder-name-dialog">
  <form [formGroup]="form" (ngSubmit)="this.onSubmitHandler()">
    <div class="content" fxLayout="column" fxLayoutAlign="space-between center">
      <mat-form-field class="name-input">
        <mat-label>Folder Name</mat-label>
        <input autocomplete="off" autofocus matInput placeholder="Folder Name" formControlName="name">
        <mat-error class="folder-name-error">{{getErrorMessage()}}</mat-error>
      </mat-form-field>
      <div class="action-group" fxLayout="row" fxLayoutAlign="end center">
        <button mat-flat-button [disabled]="!!form.invalid" color="primary" type="submit">Confirm</button>
        <button mat-flat-button (click)="onCancelHandler()">Cancel</button>
      </div>
    </div>
  </form>
</div>
