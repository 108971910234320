import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import {FolderEntityState} from '../../types/models/folder.model';
import {Pagination} from '../../types/models/pagination.model';


@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'folders', resettable: true })
export class FoldersStore extends EntityStore<FolderEntityState> {
  constructor() {
    super({
      ui: {
        pagination: null
      }
    });
  }
  updatePagination(pagination: Pagination) {
    this.update({
      ui: {
        pagination
      }
    });
  }
}
