import {Component, Input, OnInit, Output} from '@angular/core';
import { EventEmitter } from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {urlFormatValidator} from '../helper';
import {URL_ERROR2MESSAGE, DUPLICATED, REQUIRED, INCORRECT_FORMAT} from '../helper';

@Component({
  selector: 'app-url-form',
  templateUrl: './url-form.component.html',
  styleUrls: ['./url-form.component.less']
})
export class UrlFormComponent implements OnInit {

  constructor() {}

  @Output() onUrlSubmit = new EventEmitter<string>();
  @Input() existingLinks: {url: string; name?: string}[] = [];

  form = new FormGroup({
    url: new FormControl('', [
      Validators.required,
      urlFormatValidator,
      this.urlDuplicationValidator.bind(this)
    ]),
  }, {updateOn: 'submit'});

  private urlDuplicationValidator(control: AbstractControl): { [key: string]: any } | null {
    if (this.existingLinks.map(link => link.url).indexOf(control.value) > -1) {
      return {[DUPLICATED]: true};
    }
    return null;
  }

  ngOnInit(): void {
  }

  onSubmitHandler() {
    if (this.form.invalid) {
      return;
    }
    this.onUrlSubmit.emit(this.form.controls.url.value);
    this.resetForm();
  }

  private resetForm() {
    this.form.patchValue({
      url: '',
    });
    this.form.markAsPristine();
  }

  getErrorMessage() {
    if (!this.form.controls.url.dirty) {
      return null;
    }
    if (this.form.controls.url.hasError(REQUIRED)) {
      return URL_ERROR2MESSAGE[REQUIRED];
    }
    if (this.form.controls.url.hasError(INCORRECT_FORMAT)) {
      return URL_ERROR2MESSAGE[INCORRECT_FORMAT];
    }
    if (this.form.controls.url.hasError(DUPLICATED)) {
      return URL_ERROR2MESSAGE[DUPLICATED];
    }
    return null;
  }
}
