import {Component, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {catchError, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {ApiClientService} from '../../services/api-client/api-client.service';
import {AuthService} from '../../services/auth/auth.service';

@Component({
  selector: 'app-playground',
  templateUrl: './playground.component.html',
  styleUrls: ['./playground.component.less']
})
export class PlaygroundComponent implements OnInit, OnChanges {

  count = 0;
  constructor(public authService: AuthService) {
  }

  clickHandler() {
    this.count++;
  }

  ngOnInit(): void{
    console.log('INIT');
  }

  ngOnChanges(changes): void {
    console.log('CHANGED', changes);
  }
}
