import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  googleServiceUnavailable$ = new BehaviorSubject<boolean>(false);

  constructor() {
  }

  public getGoogleClientID() {
    return '630633038816-bklavvmdkp6tmiio93o41ghqfsg9cee5.apps.googleusercontent.com';
  }

  public getBaseUrl() {
    return 'https://www.site2share.com';
  }

  public getAPIBaseUrl() {
    return 'https://api-new.site2share.com';
  }

  public getTitle(subtitle?: string) {
    return `Site2Share${subtitle ? ` - ${subtitle}` : ''}`;
  }

  public markGoogleServiceUnavailable() {
    this.googleServiceUnavailable$.next(true);
  }
}
