import {Injectable} from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse, HttpErrorResponse
} from '@angular/common/http';

import {Observable} from 'rxjs';
import {catchError, filter} from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      filter(event => event instanceof HttpResponse),
      // @ts-ignore
      catchError((errResponse: HttpErrorResponse) => {
        if (errResponse.status === 401 && errResponse.url.indexOf('user/profile') === -1) {
          window.location.pathname = '/login';
        }
      })
    );
  }
}

