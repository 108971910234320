<div class="container">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <ng-content select="[header]"></ng-content>
  </div>
  <div class="content">
    <div class="panel">
      <ng-content select="[body]"></ng-content>
    </div>
  </div>
</div>
