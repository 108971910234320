import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {Location} from '@angular/common';
import { Router } from '@angular/router';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {folderNameValidator} from './helper';
import {MatDialog} from '@angular/material/dialog';
import {FolderNameFormDialogComponent} from './folder-name-form-dialog/folder-name-form-dialog.component';
import {FolderViewModel} from '../../types/models/folder.model';
import {ERROR_MESSAGES} from '../../types/enums/message-types.enum';
import {ShareDialogComponent} from '../share-dialog/share-dialog.component';
import {NotificationService} from '../../services/notification/notification.service';
import {Title} from '@angular/platform-browser';
import {ConfigurationService} from '../../services/configuration/configuration.service';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-single-folder-form',
  templateUrl: './single-folder-form.component.html',
  styleUrls: ['./single-folder-form.component.less']
})
export class SingleFolderFormComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private notificationService: NotificationService,
    private titleService: Title,
    private configurationService: ConfigurationService,
    private location: Location,
    public router: Router
  ) { }

  updatedAt = '';
  form = null;

  @Input() showEditButton = true;
  @Input() editable = true;
  @Input() folder: FolderViewModel & {id: string} = null;
  @Output() onSubmitFolderHandler = new EventEmitter();

  get links(): FormArray {
    return this.form.get('links') as FormArray;
  }

  get disableSubmit(): boolean {
    if (!this.links.value
      || !this.links.value.length
      || !this.form.controls.name.value
      || this.form.controls.name.value.length > 140) {
      return true;
    }
    return false;
  }

  ngOnInit(): void {
    this.form = this.initFormGroup(this.folder);
    this.updatedAt = this.folder.updatedAt;
    this.titleService.setTitle(this.configurationService.getTitle(this.folder.name));
  }

  private openShareDialog() {
    const folderUrl = `${this.configurationService.getBaseUrl()}/folder/${this.folder.id}`;
    this.dialog.open(ShareDialogComponent, {
      width: '300px',
      data: folderUrl
    });
  }

  onShareHandler(event: Event) {
    event.stopPropagation();
    this.openShareDialog();
  }

  initFormGroup(folderViewModel: FolderViewModel) {
    return  new FormGroup({
      name: new FormControl(folderViewModel.name, [
        Validators.required,
        folderNameValidator
      ]),
      links: new FormArray(folderViewModel.links.map(link => {
        return new FormControl(link);
      })),
    });
  }

  onOpenNameFormDialog() {
    this.dialog.open(FolderNameFormDialogComponent, {
      width: '400px',
      data: {
        name: this.form.controls.name.value,
        onConfirm: this.onNameSubmitHandler
      },
      panelClass: 'folder-name-form-panel',
    });
  }

  onUrlSubmitHandler(urlValue) {
    this.links.push(new FormControl({url: urlValue, name: ''}));
  }

  onDeleteLinkHandler(index: number): void {
    this.links.removeAt(index);
  }

  onChangeTitleHandler(index: number, event: Event): void {
    const targetLinkValue = this.links.at(index);
    targetLinkValue.patchValue({
      name: event,
      url: targetLinkValue.value.url
    })
  }

  onNameSubmitHandler = (nameValue) => {
    this.form.patchValue({
      name: nameValue
    });
  }

  onCancelHandler = () => {
    this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      id: 'cancel-creation-confirm-dialog',
      data: {
        message: `Confirm Cancel?`,
        onConfirm: () => {
          this.location.back();
        }
      }
    });
  }

  onSubmitHandler = () => {
    this.onSubmitFolderHandler.emit({
      name: this.form.controls.name.value,
      links: this.links.value,
    });
  }
}
