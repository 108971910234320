import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

export interface FolderNameDialogData {
  message: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}
@Component({
  selector: 'app-folder-name-dialog',
  templateUrl: './folder-name-dialog.component.html',
  styleUrls: ['./folder-name-dialog.component.less']
})
export class FolderNameDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<FolderNameDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: FolderNameDialogData,
              private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }
  onConfirmHandler() {
    if (this.data.onConfirm) {
      this.data.onConfirm();
    }
    this.dialogRef.close();
  }

  onCancelHandler() {
    if (this.data.onCancel) {
      this.data.onCancel();
    }
    this.dialogRef.close();
  }
}
