import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ConfigurationService} from '../../services/configuration/configuration.service';
import {Observable} from 'rxjs';
import {Folder} from '../../types/models/folder.model';
import {Pagination} from '../../types/models/pagination.model';
import {FoldersService} from '../../services/folders/folders.service';
import {FoldersQuery} from '../../services/folders/folders.query';
import {Router, ActivatedRoute} from '@angular/router';
import {PerfService} from '../../services/performance/performance.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit {

  folders$: Observable<Folder[]>;
  isLoading$: Observable<boolean>;
  error$: Observable<any>;
  page: number;

  pagination$: Observable<Pagination>;
  constructor(
    private titleService: Title,
    private configurationService: ConfigurationService,
    private foldersService: FoldersService,
    private foldersEntityQuery: FoldersQuery,
    private route: ActivatedRoute,
    private router: Router,
    private perfService: PerfService
) {
    this.page = parseInt(this.route.snapshot.queryParamMap.get('page'), 10);
    this.titleService.setTitle(this.configurationService.getTitle('Dashboard'));
  }

  loadData(page?: number): void {
    const currentPage = page ||  this.page || 1;
    this.foldersService.getFolders(currentPage).subscribe();

    if (page) {
      const url = this.router.createUrlTree([], {relativeTo: this.route, queryParams: {page: currentPage, }}).toString();
      this.router.navigateByUrl(url).then(() => {});
    }

    this.folders$ = this.foldersEntityQuery.getFolders();
    this.isLoading$ = this.foldersEntityQuery.selectLoading();
    this.error$ = this.foldersEntityQuery.selectError();
    this.pagination$ = this.foldersEntityQuery.getPagination();
  }

  ngOnInit(): void {
    this.perfService.trackElementShowUp(
      'dashboard:visible',
      '.my-folders-container'
    );
    this.loadData();
  }

}
