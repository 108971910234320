<div mat-dialog-content class="folder-name-dialog">
  <div class="content" fxLayout="column" fxLayoutAlign="space-between center">
    <mat-form-field class="name-input">
      <mat-label>Folder Name</mat-label>
      <input matInput placeholder="Ex. Pizza" value="Sushi">
    </mat-form-field>
    <div class="action-group" fxLayout="row" fxLayoutAlign="end center">
      <button mat-flat-button color="primary" (click)="onConfirmHandler()">Confirm</button>
      <button mat-flat-button (click)="onCancelHandler()">Cancel</button>
    </div>
  </div>
</div>
