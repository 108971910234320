<ng-container *ngIf="(folder$ | async) && !(folder$ | async).editable">
  <app-forbidden></app-forbidden>
</ng-container>

<ng-container *ngIf="(isLoading$ | async) else folderDetail">
  <app-page-loading-indicator></app-page-loading-indicator>
</ng-container>

<ng-template #folderDetail>
  <ng-container *ngIf="(folder$ | async) && (folder$ | async).editable">
    <app-single-folder-form [editable]="true" (onSubmitFolderHandler)="editFolder($event)" [folder]="folder$ | async | toFolderViewModel">
    </app-single-folder-form>
  </ng-container>
</ng-template>
