import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.less']
})
export class PaginationComponent implements OnInit {

  VISIBLE_PAGE_COUNT = 5;
  @Input() total = 1;
  @Input() current = 1;

  @Output() onTurnNextPage = new EventEmitter<number>();
  @Output() onTurnPrevPage = new EventEmitter<number>();
  @Output() onGoToPage = new EventEmitter<number>();

  firstPage = 1;

  get prevPage(): number {
    return this.current - 1;
  }

  get nextPage(): number {
    return this.current + 1;
  }

  get pageRange(): (string | number)[] {
    const initialRange: (number|string) [] = [];
    if (this.total <= this.VISIBLE_PAGE_COUNT) {
      let temp = 1;
      while (temp <= this.total) {
        initialRange.push(temp);
        temp++;
      }
      return initialRange;
    }
    initialRange.push(this.current);
    if (this.current !== this.firstPage) {
     initialRange.unshift(this.firstPage);
     if (this.prevPage !== this.firstPage) {
        const currentPageIndex = initialRange.indexOf(this.current);
        initialRange.splice(currentPageIndex, 0, this.prevPage);
        if (this.prevPage - this.firstPage > 1) {
          initialRange.splice( 1, 0, '...');
        }
      }
    }
    if (this.current !== this.total) {
      initialRange.push(this.total);
      if (this.nextPage !== this.total) {
        const currentPageIndex = initialRange.indexOf(this.current);
        initialRange.splice(currentPageIndex + 1, 0, this.nextPage);
        if (this.total - this.nextPage > 1) {
          const lastPageIndex = initialRange.indexOf(this.total);
          initialRange.splice( lastPageIndex, 0, '...');
        }
      }
    }
    return initialRange;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
