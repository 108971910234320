<div class="folder-item" fxLayout="row" fxFlexFill fxLayoutAlign="space-between center"
     (click)="goToFolderDetail(this.folder.id)">
  <mat-progress-bar *ngIf="isLoading" class="loading-meta-bar" mode="indeterminate"></mat-progress-bar>
  <div *ngIf="isLoading" class="overlay-mask"></div>
  <div fxLayout="row" fxLayoutAlign="space-around center">
    <div class="field folder-name">
      <div class="field-value ellipsis">{{this.folder.name}}</div>
    </div>
    <div class="field folder-modify-time">
      <div class="field-value">{{convertToLocalTime(this.folder.updatedAt)}}</div>
    </div>
  </div>
  <div class="action-group">
    <div fxLayout="row" fxLayoutAlign="space-around center">
      <button (click)="onShareHandler($event)" mat-icon-button class="btn-action" matTooltip="Share">
        <mat-icon>share</mat-icon>
      </button>
      <button (click)="onTriggerMenuHandler($event)" mat-icon-button [matMenuTriggerFor]="menu" class="btn-action">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu" class="folder-action-menu">
        <button mat-menu-item>
          <a class="edit-redirect-link" [routerLink]="'/folder/' + this.folder.id +  '/edit'">
            <mat-icon>edit</mat-icon>
            <span>Edit</span>
          </a>
        </button>
        <button mat-menu-item (click)="onDeleteFolderHandler(this.folder.id)">
          <mat-icon>delete</mat-icon>
          <span>Delete</span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>
