<ng-container *ngIf="link else nativeLinkTemplate">
  <mat-toolbar [ngClass]="'banner ' + extraClassname" color="primary">
    <a class="content" [routerLink]="link" ><span>{{text}}</span></a>
  </mat-toolbar>
</ng-container>
<ng-template #nativeLinkTemplate>
  <mat-toolbar [ngClass]="'banner ' + extraClassname" color="primary">
    <a class="content" [href]="nativeLink" ><span>{{text}}</span></a>
  </mat-toolbar>
</ng-template>
