<mat-progress-bar style="display: none" id="loading-indicator-bar" class="loading-meta-bar" mode="indeterminate">
</mat-progress-bar>
<div class="header">
  <div class="content">
    <a class="logo" href="https://chrome.google.com/webstore/detail/share-tabs/dneibnomihlihbnibiblbmeppafmeacg" target="_blank">
    <button  mat-flat-button color="primary" type="button">
      <span class="text">Install Chrome Extension</span>
    </button>
    </a>
  </div>
</div>
<div class="hero">
  <div class="content">
    <h1 class="title">Share Your Boomarks</h1>
    <button mat-stroked-button type="button" class="try-button">
      <a href="https://www.site2share.com/folder/20020536" target="_blank" class="text">Try it now</a>
    </button>
    <img class="screenshot" src="/assets/screenshot.png" />
  </div>
</div>
<div class="section bg-color-blue">
  <div class="content">
    <h1 class="title">Access from <a href="https://chrome.google.com/webstore/detail/share-tabs/dneibnomihlihbnibiblbmeppafmeacg" target="_blank">Chrome extension</a></h1>
    <img class="screenshot" src="/assets/chrome_extension.jpg" />
  </div>
</div>
<div class="section bg-color-yellow">
  <div class="content">
    <h1 class="title">Share To Your Friends</h1>
    <img class="screenshot" src="/assets/share.png" />
  </div>
</div>
<div class="section bg-color-blue">
  <div class="content">
    <h1 class="title">Customize Your Bookmark</h1>
    <img class="screenshot" src="/assets/folder_name.png" />
  </div>
</div>
<div class="section bg-color-yellow">
  <div class="content">
    <h1 class="title">Mobile Friendly</h1>
    <img class="screenshot" src="/assets/mobile.png" />
  </div>
</div>
<div class="footer">
  <div class="content">
      <a class="contact" href="mailto:hi@site2share.com">Contact</a>
  </div>
</div>