import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth/auth.service';
import {UserQuery} from '../../services/user/user.query';
import {UserService} from '../../services/user/user.service';
import {Observable} from 'rxjs';
import {User} from '../../types/models/user.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {

  user$: Observable<User>;
  isLoading$: Observable<boolean>;
  isLogin$: Observable<boolean>;
  onLogout = false;

  constructor(
    public authService: AuthService,
    private userQuery: UserQuery,
    private userService: UserService
  ) {
  }


  ngOnInit(): void {
    // Do not delete handlers in the subscribe callback function, this will cause test failed
    this.userService.getUserProfile().subscribe(() => {}, () => {});
    this.user$ = this.userQuery.getProfile();
    this.isLogin$ = this.userQuery.checkIsLogin();
    this.isLoading$ = this.userQuery.selectLoading();
  }

  public logoutHandler() {
    this.onLogout = true;
    this.authService.logout().subscribe(res => {
      window.location.pathname = '/login';
    });
  }
}
