import {Input, Output, Component, OnInit, EventEmitter} from '@angular/core';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import * as _ from 'lodash';

@Component({
  selector: 'app-link-item',
  templateUrl: './link-item.component.html',
  styleUrls: ['./link-item.component.less']
})
export class LinkItemComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
  ) { }

  @Input() name = '';
  @Input() url = '';
  @Input() id = '';

  @Input() showAction = false;

  @Output() onDelete = new EventEmitter();
  @Output() onChangeTitle = new EventEmitter();

  ngOnInit(): void {
  }

  onDeleteLinkHandler(event: Event) {
    event.preventDefault();
    this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        message: `Confirm Delete?`,
        onConfirm: () => {
          this.onDelete.emit();
        }
      }
    });
  }

  onChangeTitleHandler(event: Event) {
    event.preventDefault();
    const name = window.prompt('Name for this link', this.name);
    if (_.isNil(name)) {
      return;
    }
    if (name.trim().length >= 1000) {
      alert('Max 1000 characters');
      return;
    }
    this.onChangeTitle.emit(name);
  }
}
