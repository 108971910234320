<form class="url-form" [formGroup]="form" (ngSubmit)="this.onSubmitHandler()">
  <div class="url-form-container">
    <input
      autofocus
      autocomplete="off"
      class="form-input url-input"
      placeholder="http://example.com"
      formControlName="url"
    />
    <button class="submit-url-button" mat-flat-button color="primary">
      Add
    </button>
  </div>
  <mat-error *ngIf="form.controls.url.errors" class="url-form-error">{{getErrorMessage()}}</mat-error>
</form>
