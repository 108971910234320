import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {ConfigurationService} from '../configuration/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ApiClientService {

  constructor(private configurationService: ConfigurationService) { }

  private domain = this.configurationService.getAPIBaseUrl();

  public folders(page: number = 1): string {
    return `${this.domain}/api/v1/user/folders?page=${page}`;
  }

  public folder(id?: number | string | undefined): string {
    return `${this.domain}/api/v1/folder${id ? `/${id}` : ''}`;
  }

  public profile(): string {
    return `${this.domain}/api/v1/user/profile`;
  }

  public verify(token?: number | string | undefined): string {
    return `${this.domain}/api/v1/login/google?token=${token}`;
  }

  public logout(): string {
    return `${this.domain}/api/v1/logout`;
  }
}
