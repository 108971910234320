import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import {MatTreeModule} from '@angular/material/tree';
import {MatRippleModule} from '@angular/material/core';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSnackBarModule} from '@angular/material/snack-bar';

import {DashboardComponent} from './components/dashboard/dashboard.component';
import {FolderItemComponent} from './components/folder-item/folder-item.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatChipsModule} from '@angular/material/chips';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {AddNewFolderPlaceholderComponent} from './components/add-new-folder-placeholder/add-new-folder-placeholder.component';
import {HeaderComponent} from './components/header/header.component';
import {SettingsComponent} from './components/settings/settings.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {LinkItemComponent} from './components/link-item/link-item.component';
import {AkitaNgDevtools} from '@datorama/akita-ngdevtools';
import {environment} from '../environments/environment';
import {ErrorMessageComponent} from './components/error-message/error-message.component';
import {ShareDialogComponent} from './components/share-dialog/share-dialog.component';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {NotificationBarComponent} from './components/notification-bar/notification-bar.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ActionBarComponent} from './components/action-bar/action-bar.component';
import {LoadingModalComponent} from './components/loading-modal/loading-modal.component';
import {PlaygroundComponent} from './components/playground/playground.component';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {FolderLayoutComponent} from './components/folder-layout/folder-layout.component';
import {DummyComponent} from './components/dummy-component/dummy.component';
import {FolderNameDialogComponent} from './components/folder-name-dialog/folder-name-dialog.component';
import {SingleFolderFormComponent} from './components/single-folder-form/single-folder-form.component';
import {UrlFormComponent} from './components/single-folder-form/url-form/url-form.component';
import {FolderNameFormDialogComponent} from './components/single-folder-form/folder-name-form-dialog/folder-name-form-dialog.component';
import {SingleFolderCreateContainerComponent} from './components/single-folder-create-container/single-folder-create-container.component';
import {SingleFolderEditContainerComponent} from './components/single-folder-edit-container/single-folder-edit-container.component';
import {SingleFolderContainerComponent} from './components/single-folder-container/single-folder-container.component';
import {ToFolderViewModelPipe} from './pipes/to-folder-view-model/to-folder-view-model.pipe';
import {ToLocalTimePipe} from './pipes/to-local-time/to-local-time.pipe';
import {PageLoadingIndicatorComponent} from './components/page-loading-indicator/page-loading-indicator.component';
import {ForbiddenComponent} from './components/forbidden/forbidden.component';
import {ErrorLayoutComponent} from './components/error-layout/error-layout.component';
import {LoginComponent} from './components/login/login.component';
import {BannerComponent} from './components/banner/banner.component';
import {ServiceUnavailableBannerComponent} from './components/service-unavailable-banner/service-unavailable-banner.component';

import {httpInterceptorProviders} from './http-interceptors';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    FolderItemComponent,
    AddNewFolderPlaceholderComponent,
    HeaderComponent,
    SettingsComponent,
    PaginationComponent,
    LinkItemComponent,
    ErrorMessageComponent,
    ShareDialogComponent,
    NotificationBarComponent,
    ActionBarComponent,
    LoadingModalComponent,
    PlaygroundComponent,
    ConfirmDialogComponent,
    PageNotFoundComponent,
    FolderLayoutComponent,
    DummyComponent,
    FolderNameDialogComponent,
    SingleFolderFormComponent,
    UrlFormComponent,
    FolderNameFormDialogComponent,
    SingleFolderCreateContainerComponent,
    SingleFolderEditContainerComponent,
    SingleFolderContainerComponent,
    ToFolderViewModelPipe,
    ToLocalTimePipe,
    PageLoadingIndicatorComponent,
    ForbiddenComponent,
    ErrorLayoutComponent,
    LoginComponent,
    ServiceUnavailableBannerComponent,
    BannerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,

    FlexLayoutModule,

    MatButtonModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatListModule,
    MatTreeModule,
    MatRippleModule,
    MatTabsModule,
    MatTooltipModule,
    MatChipsModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSnackBarModule,

    environment.production ? [] : AkitaNgDevtools.forRoot(),
    ReactiveFormsModule
  ],
  providers: [
    httpInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
