import {AfterViewInit, Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth/auth.service';
import {PerfService} from '../../services/performance/performance.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
  constructor(
    public authService: AuthService,
    private perfService: PerfService
  ) {
    this.authService.onVerifyGoogleAuthIdToken$.subscribe({
      next: (res) => {
        // `onVerifyGoogleAuthIdToken$ | async` in template not working,
        // so I have to control loading-bar visibility by myself
        const loadingBar = document.querySelector('#loading-indicator-bar') as HTMLDivElement;
        if (!loadingBar) {
          return;
        }
        const header = document.querySelector('.header') as HTMLDivElement;
        if (res) {
          loadingBar.style.display = 'block';
          header.style.top = '5px';
        } else {
          loadingBar.style.display = 'none';
          header.style.top = '0px';
        }
      }
    });
  }

  ngOnInit() {
    this.perfService.trackElementShowUp(
      'login:visible',
      '.login-container'
    );
  }

}
