import {Injectable} from '@angular/core';
import {StoreConfig, Store} from '@datorama/akita';
import {Folder} from '../../types/models/folder.model';

export interface FolderState {
  folder: Folder;
}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({name: 'folder', resettable: true})
export class FolderStore extends Store<FolderState> {
  constructor() {
    super({
      folder: null
    });
  }
  updateFolder(folder?: Folder) {
    this.update({
      folder,
    });
  }
}
