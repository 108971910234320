<ul class="pagination" fxLayout="row" fxLayoutAlign="center center">
  <ng-container *ngIf="total > 1">
    <li (click)="onTurnPrevPage.emit(this.current - 1)" *ngIf="current != firstPage" class="pagination-item" id="pagination-prev-page" fxLayout="row" fxLayoutAlign="center center">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </li>
    <li (click)="onGoToPage.emit(tempPage)" *ngFor="let tempPage of pageRange " [ngClass]="{'pagination-item': true, 'active': tempPage === current }" >
      {{tempPage}}
    </li>
    <li (click)="onTurnNextPage.emit(this.current + 1)" *ngIf="current != total" class="pagination-item" fxLayout="row" id="pagination-next-page" fxLayoutAlign="center center">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </li>
  </ng-container>
</ul>
