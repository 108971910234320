import {Component, OnInit} from '@angular/core';
import {MESSAGE_TYPES} from '../../types/enums/message-types.enum';

@Component({
  selector: 'app-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.less']
})
export class NotificationBarComponent implements OnInit {

  constructor() { }

  message = '';
  type = MESSAGE_TYPES.ERROR;
  get messageColor(): string {
    return this.type === MESSAGE_TYPES.ERROR ? 'warn' : 'primary';
  }
  get messageVisible(): boolean {
    return !!this.message;
  }
  get isLoading(): boolean {
    return this.type === MESSAGE_TYPES.LOADING;
  }

  ngOnInit(): void {
  }

  onClose(): void {
    this.message = '';
  }

}
