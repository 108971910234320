<div class="container">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="title">Settings</h1>
  </div>
  <div class="content">
    <div class="setting" fxLayout="row" fxLayoutAlign="space-between center">
      <div>
        <label class="name">Public My Created Folders</label>
        <p class="explain">Can anyone see my created folders</p>
      </div>
      <mat-slide-toggle></mat-slide-toggle>
    </div>
    <div class="setting" fxLayout="row" fxLayoutAlign="space-between center">
      <div>
        <label class="name">Public My Favourite Folders</label>
        <p class="explain">Can anyone see my created folders</p>
      </div>
      <mat-slide-toggle></mat-slide-toggle>
    </div>
  </div>
</div>
