import {Component, Inject, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import QRCode from 'qrcode';
import {NotificationService} from '../../services/notification/notification.service';
import {SUCCESS_MESSAGES} from '../../types/enums/message-types.enum';

@Component({
  selector: 'app-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.less']
})
export class ShareDialogComponent implements OnInit, AfterViewInit {

  @ViewChild('qrCodeCanvas') qrCodeCanvas;

  ngOnInit(): void {
  }

  constructor(
    public dialogRef: MatDialogRef<ShareDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private notificationService: NotificationService) {
  }

  ngAfterViewInit() {
    this.updateQRCodeCanvasByText(this.data);
  }

  public updateQRCodeCanvasByText(text: string) {
    QRCode.toCanvas(this.qrCodeCanvas.nativeElement, text, {
      width: 250,
      height: 250,
    }, (error) => {
      if (error) {
        console.error(error);
      }
    });
  }

  public copyUrl(): void {
    const tempCopyInputElement = document.createElement('input');
    tempCopyInputElement.value = this.data;
    document.querySelector('body').appendChild(tempCopyInputElement);
    tempCopyInputElement.select();
    document.execCommand('copy');
    tempCopyInputElement.parentNode.removeChild(tempCopyInputElement);
    this.notificationService.message(SUCCESS_MESSAGES.COPY_SUCCESS);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
